import React from 'react'
import styled, { css } from 'styled-components'
import { PackageTariffCard } from './PackageTariffCard'
import { PackageTariffInformation } from './TariffInformation'
import { size } from '../constants'

const packageTariff = [
  {
    points: 'от 30 000',
    pointsName: 'баллов',
    price: '27 000 ₽',
    prevPrice: '30 000 ₽',
    reduction: 10
  },
  {
    points: 'от 20 000',
    pointsName: 'баллов',
    price: '18 600 ₽',
    prevPrice: '20 000 ₽',
    reduction: 7
  },
  {
    points: 'от 10 000',
    pointsName: 'баллов',
    price: '9 500 ₽',
    prevPrice: '10 000 ₽',
    reduction: 5
  }
]

export const PackagePricelist = styled(({ className, type }) => (
  <div className={className}>
    {packageTariff.map((tariff, index) => (
      <PackageTariffCard key={index} type={type} {...tariff} />
    ))}
  </div>
))`
  display: grid;
  grid-template-columns: repeat(3, 222px);
  gap: 24px;
  padding-left: 225px;

  @media (max-width: ${size.lg}) {
    grid-template-columns: repeat(3, 200px);
    padding-left: 0;
    gap: 12px;
  }

  @media (max-width: ${size.md}) {
    gap: 24px;
    padding-left: 0;
    padding-bottom: 20px;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }

  @media (max-width: ${size.xs}) {
    grid-template-columns: repeat(3, 288px);
  }

  ${({ page }) => {
    const reduction = packageTariff.find(({ reduction }) => reduction)
    return (
      reduction &&
      page === 'tariff' &&
      css`
        margin-top: 15px;

        @media (max-width: ${size.lg}) {
          margin-top: 7px;
        }

        @media (max-width: ${size.sm}) {
          margin-top: 0;
        }
      `
    )
  }}
`
export const PackageTariffType = styled(({ className }) => (
  <div className={className}>
    <PackageTariffInformation />
    <PackagePricelist />
  </div>
))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 165px 0px 0px;
  width: auto;
  gap: 32px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1440px) {
    max-width: 1224px;
  }

  @media (max-width: ${size.lg}) {
    padding: 20px 0px 0px 20px;
    gap: 0px;
  }

  @media (max-width: ${size.md}) {
    flex-direction: column;
    padding: 20px 0px 20px 0px;
    margin-left: 20px;
    gap: 24px;
  }

  @media (max-width: ${size.sm}) {
    margin-right: unset;
    align-items: center;
    margin-left: 0px;
  }
`
