import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BritishFlagSVG } from '../../../static/img/icons/language/british.svg'
import { CheckboxTabsToggler } from '../CheckboxToggler'
import { Language } from '../../atoms/mappers/gqlEnums/language'
import { ReactComponent as RussianFlagSVG } from '../../../static/img/icons/language/russian.svg'
import { WizardCheckbox } from '../../atoms/Checkbox'
import { size } from '../../constants'

const tabsData = [
  {
    id: Language.Russian,
    text: 'На русском',
    value: Language.Russian,
    Icon: <RussianFlagSVG />
  },
  {
    id: Language.English,
    text: 'На английском',
    value: Language.English,
    Icon: <BritishFlagSVG />
  }
]

export const LanguageCheckboxGroup = styled(
  ({ className, checked, handleCheck }) => (
    <CheckboxTabsToggler
      checkboxes={tabsData}
      checked={checked}
      className={className}
      handleCheck={handleCheck}
    />
  )
)`
  width: fit-content;
  height: 40px;

  @media (max-width: ${size.sm}) {
    width: 100%;
    height: 48px;
  }

  @media (max-width: ${size.xs}) {
    ${WizardCheckbox} label {
      padding-right: 7px;
      padding-left: 7px;
    }
  }
`
