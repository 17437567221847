import '../styles/Tariff.scss'
import AdvantagePoppup from '../components/AdvantagePoppup'
import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Modal from '../components/Modal'
import React, { useCallback, useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import SEO, { getSeoMedia } from '../atoms/SEO'
import styled from 'styled-components'
import { BreadCrumbs, BreadcrumbSEO } from '../molecules/BreadCrumbs'
import {
  BreadcrumbJsonLd,
  FAQJsonLd,
  ProductJsonLd
} from 'gatsby-plugin-next-seo'
import { Grid } from '../atoms/Grid'
import { LanguageCheckboxGroup } from '../molecules/LanguageCheckboxGroup'
import { QuestionsList } from '../molecules/QuestionsList'
import { TariffTypes } from '../organisms/TariffTypes'
import { Title } from '../atoms/Title'
import { color } from '../styles/vars/colors'
import { questionListTariff } from '../molecules/QuestionsList/questionsListTariff'
import { selectDeviceSize } from '../state/reducers/deviceSlice'
import { size } from '../constants'
import { useSelector } from 'react-redux'

const PaymentsToggler = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: ${size.sm}) {
    flex-direction: column-reverse;
    width: 100%;
  }
`

const Prices = styled(Grid)`
  background: #eff5fb;
  width: 100%;
  max-width: 100%;

  & ${Title.H1} {
    margin-bottom: 40px;
  }

  ${PaymentsToggler} {
    margin-bottom: 40px;
  }

  @media (max-width: ${size.lg}) {
    ${PaymentsToggler} {
      margin-bottom: 32px;
    }

    & ${Title.H1} {
      margin-bottom: 32px;
    }
  }

  @media (max-width: ${size.md}) {
    position: relative;

    & ${LanguageCheckboxGroup} {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  @media (max-width: ${size.sm}) {
    position: relative;

    & ${LanguageCheckboxGroup} {
      position: relative;
      width: 100%;
      max-width: 100%;
      top: unset;
      right: unset;
    }

    ${PaymentsToggler} {
      margin-bottom: 24px;
    }
  }

  @media (max-width: ${size.xs}) {
    ${PaymentsToggler} {
      margin-bottom: 16px;
    }

    & ${Title.H1} {
      margin-bottom: 24px;
    }
  }
`

const JsonLdQuestionListData = questionListTariff.map(({ ask, ans }) => ({
  question: ask,
  answer: ReactDOMServer.renderToStaticMarkup(ans())
}))

const Tariff = () => {
  const isDocument = () => typeof document !== 'undefined'
  let root

  useEffect(() => {
    root = document.querySelector('.root')
  }, [])

  useEffect(() => {
    if (isDocument()) {
      root.scrollTo(0, 0)
      root.classList.remove('root_fixed')
    }
  }, [root])
  const wWidth = useSelector(selectDeviceSize)

  const [isShowing, setIsShowing] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [filterId] = useState('one')
  const [subFilterId] = useState('async')

  const tglPopup = useCallback(() => {
    if (isShowing) {
      setIsVisible(false)
      setTimeout(() => {
        setIsShowing(false)
      }, 300)
    } else {
      setIsShowing(true)
      setTimeout(() => {
        setIsVisible(true)
      }, 0)
    }
  }, [isShowing])

  return (
    <>
      <App>
        <SEO
          children={
            <>
              <BreadcrumbSEO />
              <FAQJsonLd questions={JsonLdQuestionListData} />
            </>
          }
          description="Онлайн-консультация психолога от 2000 ₽ ✔Тарифы на индивидуальную и семейную психотерапию, а также прием для ребенка ➨Узнать, сколько стоит психолог, и записаться на сайте сервиса YouTalk"
          images={getSeoMedia([
            { pathname: '/img/og/tariff.png', alt: 'tariff' }
          ])}
          title="Тарифы на консультацию психолога — цены: стоимость услуг психолога онлайн"
        />
        <ProductJsonLd
          children={
            <>
              <BreadcrumbJsonLd
                itemListElements={[
                  {
                    position: 1,
                    name: 'Главная',
                    item: String(process.env.GATSBY_SITEURL)
                  },
                  {
                    position: 2,
                    name: 'Тарифы',
                    item: `${process.env.GATSBY_SITEURL}/tariff`
                  }
                ]}
              />
            </>
          }
          brand="YouTalk"
          description="Выбор тарифа для работы с онлайн-психологом. Индивидуальные консультации, работа с семьей, парой или родителями с детьми. Видео и аудио формат, синхронная и асинхронная переписка."
          offers={{
            '@type': 'AggregateOffer',
            offerCount: '13',
            lowPrice: '2500',
            highPrice: '37800',
            priceCurrency: 'RUB',
            seller: {
              name: 'YouTalk'
            }
          }}
          productName="Тариф"
        />
        <Header />
        <BreadCrumbs fill={color.tariff.background} />
        <main className="main">
          <section className="tariff-top">
            <div className="container">
              <div className="row">
                <div className="designer-col col-lg-12">
                  <div className="tariff-top__wrap tariff-top-wrap">
                    <Title.H1>Тарифы психологов</Title.H1>
                    <ul>
                      <li>
                        Стоимость консультации психолога по видео, аудио и
                        переписке
                        <br />в нашем сервисе от 2000 ₽
                      </li>
                      <li>1 балл = 1 рублю</li>
                      <li>Бесплатный подбор и переподбор специалиста</li>
                      <li>
                        Принимаем оплату из-за рубежа{' '}
                        <a href="/sposoby-oplaty/">подробнее</a>{' '}
                      </li>
                    </ul>
                    <div className="tariff-top-wrap__img-wrap">
                      {wWidth > 767 && (
                        <>
                          <img
                            alt="Изображение пяти золотых монеток и четырех ромбов, парящих в воздухе"
                            className="tariff-top-wrap__decor tariff-top-wrap__decor_middle"
                            src="/img/icons/tariff/coins.svg"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tariff-full">
            <Prices>
              <TariffTypes tglPopup={tglPopup} />
            </Prices>
            <div className="tariff-full__decor-bg" />
          </section>
          <section className="questions questions_tariff">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12 col-lg-10">
                  <h2 className="questions__title h1">Вопросы</h2>
                  <ul className="questions__list questions-list">
                    <QuestionsList eventGA="TariffQuestion" />
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
        {isShowing && (
          <Modal hide={tglPopup} isVisible={isVisible}>
            <AdvantagePoppup filterId={filterId} subFilterId={subFilterId} />
          </Modal>
        )}
      </App>
    </>
  )
}

export default Tariff
