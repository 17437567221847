import './index.scss'
import React from 'react'

const AdvantagePoppup = ({ filterId, subFilterId }) => (
  <div className="popup-content">
    <div className="popup-content__list-block list-block">
      <p className="list-block__title">Преимущества</p>
      {filterId !== 'write' ? (
        <ul className="list-block__list doted-list">
          <li className="doted-list__item">
            Общение с психологом в режиме реального времени
          </li>
          <li className="doted-list__item">
            В начале работы позволяет быстро наладить контакт и понять друг
            друга
          </li>
          <li className="doted-list__item">
            Даёт возможность глубоко погрузиться в проблему и подробно обсудить
            все детали
          </li>
          <li className="doted-list__item">
            Открывает возможность использовать широкий диапазон приёмов и техник
          </li>
        </ul>
      ) : (
        <ul className="list-block__list doted-list">
          {subFilterId === 'async' ? (
            <>
              <li className="doted-list__item">
                Можно писать психологу каждый день
              </li>
              <li className="doted-list__item">
                Регулярная поддержка, домашние задания и упражнения
              </li>
              <li className="doted-list__item">
                Возможность выражать свои эмоции тогда, когда они появились
              </li>
              <li className="doted-list__item">
                Не нужно выделять отдельное время для консультации
              </li>
            </>
          ) : (
            <>
              <li className="doted-list__item">
                У окружающих людей нет возможности услышать о чём вы говорите с
                психологом
              </li>
              <li className="doted-list__item">
                Возможно перечитать диалог, если вы что-то забыли
              </li>
            </>
          )}
        </ul>
      )}
    </div>
    <div className="popup-content__list-block list-block">
      <p className="list-block__title">Ограничения</p>
      {filterId !== 'write' ? (
        <ul className="list-block__list doted-list">
          <li className="doted-list__item">
            Отсутствие ежедневной поддержки психолога
          </li>
          <li className="doted-list__item">
            Невозможность общаться до и после аудио- или видеосессии
          </li>
          <li className="doted-list__item">
            Качество работы зависит от скорости интернета клиента на момент
            связи
          </li>
        </ul>
      ) : (
        <ul className="list-block__list doted-list">
          {subFilterId === 'async' && (
            <li className="doted-list__item">
              Не дает возможность общаться с психологом в режиме реального
              времени
            </li>
          )}
          <li className="doted-list__item">
            Углубление в проблему и прояснение деталей может занимать длительное
            время
          </li>
          <li className="doted-list__item">
            Не позволяет психологу быстро оценить состояние клиента, поэтому не
            подходит для людей в кризисном состоянии, клиентам с суицидальными
            попытками в прошлом, а также с суицидальными мыслями и тенденцией к
            самоповреждению в настоящем
          </li>
        </ul>
      )}
    </div>
  </div>
)

export default AdvantagePoppup
