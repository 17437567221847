import React from 'react'
import styled, { css } from 'styled-components'
import { color } from '../styles/vars/colors'
import { size } from '../constants'

const Card = styled.li`
  list-style-type: none;
  height: 171px;
  padding: 24px;
  border-radius: 24px;
  background-color: #fff;
  border: 1px solid #c1c5cd;
  position: relative;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;

  @media (max-width: ${size.sm}) {
    display: grid;
    grid-template-columns: 76px 1fr;
    gap: 40px;
    padding: 16px;
    height: auto;
    width: 385px;
  }
  @media (max-width: ${size.xs}) {
    width: 288px;
  }
  @media (max-width: ${size.xxs}) {
    width: 288px;
  }
`

const Reduction = styled.span`
  position: absolute;
  top: 0;
  right: 8px;
  padding: 6px 8px;
  background: ${color.tariff.card.reduction};
  border-radius: 24px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  transform: translateY(-50%);

  @media (max-width: ${size.sm}) {
    transform: translateY(-9px);
    right: 16px;
  }
`

const Price = styled.div`
  margin-bottom: 24px;
`

Price.Number = styled.span`
  font-weight: 700;
  color: ${color.tariff.card.title};
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
    position: absolute;
    right: 90px;
  }

  @media (max-width: ${size.xs}) {
    right: ${({ right }) => right || '90px'};
  }

  @media (max-width: ${size.xxs}) {
    right: ${({ right }) => right || '110px'};
  }
`

Price.Name = styled.span`
  font-weight: 700;
  color: ${color.tariff.card.title};
  font-size: 18px;
  line-height: 26px;
  margin-left: 4px;

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

Price.Prev = styled.span`
  font-weight: 700;
  color: ${color.tariff.card.title};
  font-size: 18px;
  line-height: 26px;
  margin-left: 4px;

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const Points = styled.div`
  height: 82px;
  display: grid;
  grid-template-rows: 20px 32px 20px;
  gap: 5px;

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    grid-template-rows: unset;
    height: auto;
    gap: 2px;

    ${({ prevPrice }) =>
      !prevPrice &&
      css`
        & ${Points.Prev} {
          display: none;
        }
      `}
  }
`

Points.Prev = styled.span`
  display: block;
  color: ${color.text.dark};
  text-decoration: line-through;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`

Points.Current = styled.span`
  display: block;
  color: #333333;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`
Points.Name = styled.span`
  display: block;
  color: #333333;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;

  @media (max-width: ${size.xs}) {
    font-size: 12px;
    line-height: 26px;
  }
`

Points.Discount = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(155, 155, 155, 1);
  white-space: nowrap;
  text-decoration: line-through;

  @media (max-width: ${size.md}) {
    font-size: 12px;
    line-height: 26px;
  }

  @media (max-width: ${size.sm}) {
    font-size: 12px;
    line-height: 26px;
    position: absolute;
    right: 90px;
    top: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 12px;
    line-height: 26px;
    position: absolute;
    right: ${({ right }) => right || '110px'};
    top: 45px;
  }

  @media (max-width: ${size.xxs}) {
    font-size: 12px;
    line-height: 26px;
    position: absolute;
    right: ${({ right }) => right || '90px'};
    top: 40px;
  }
`
export const PackageTariffCard = styled(
  ({ className, reduction, price, points, pointsName, prevPrice }) => (
    <Card className={className}>
      {reduction && <Reduction>-{reduction}%</Reduction>}
      <Points>
        <Points.Current>{points}</Points.Current>
        <Points.Name>{pointsName}</Points.Name>
        {prevPrice && (
          <Points.Discount right={'104px'}>{prevPrice}</Points.Discount>
        )}
      </Points>
      <Price>
        <Price.Number right={'76px'}>{price}</Price.Number>
      </Price>
    </Card>
  )
)``
