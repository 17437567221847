import React from 'react'
import styled from 'styled-components'
import { Title } from '../atoms/Title'
import { size } from '../constants'

// const Span = styled.span`
//   @media (max-width: ${size.xs}) {
//     font-size: 18px;
//     line-height: 26px !important;
//   }
// `

const StyledDiv = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: ${size.xs}) {
    font-size: 14px !important;
  }
`

export const PackageTariffInformation = styled(({ className }) => {
  return (
    <div className={className}>
      <Title.PasH2Styles>Пакеты баллов со&nbsp;скидкой</Title.PasH2Styles>
      <StyledDiv>Покупать больше баллов выгодно</StyledDiv>
    </div>
  )
})`
  @media (max-width: ${size.sm}) {
    margin-top: 24px;
    align-self: start;
  }

  @media (max-width: ${size.xs}) {
    margin-left: 0;
    margin-top: 24px;
  }

  & ${Title.PasH2Styles} {
    margin-bottom: 16px;
    width: 350px;
    font-size: 32px;
    line-height: 40px;

    @media (max-width: ${size.md}) {
      width: 100%;
    }

    @media (max-width: ${size.sm}) {
      margin-bottom: 0;
      font-size: 24px;
      line-height: 32px;
    }

    @media (max-width: ${size.xs}) {
      width: 450px;
      font-size: 18px;
      line-height: 26px;
    }
  }
`
