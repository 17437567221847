import React from 'react'
import styled from 'styled-components'
import { PackageTariffType } from '../molecules/TariffType'
import { size } from '../constants'

export const TariffTypes = styled(({ className }) => (
  <div className={className}>
    <PackageTariffType />
  </div>
))`
  display: flex;
  flex-direction: column;
  height: 226px;
  gap: 40px;

  @media (max-width: ${size.md}) {
    height: auto;
    gap: 24px;
  }

  @media (max-width: ${size.xs}) {
    gap: 24px;
  }
`
